<template>
<v-container>
    <v-row>
        <v-col cols="12" md="6">
            <receiver-info-card/>
        </v-col>
        <v-col cols="12" md="6">
            <pre-order-preview-card @clicked="placeOrder"/>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import PreOrderPreviewCard from '../components/ShopParts/PreOrderPreviewCard.vue'
import ReceiverInfoCard from '../components/ShopParts/ReceiverInfoCard.vue'
    export default {
        components: { PreOrderPreviewCard, ReceiverInfoCard },
        name: 'Checkout',
        computed : {
            ...mapState({
                cart : state => state.cart,
                authToken : state => state.authToken,
                recipient : state => state.recipient,
            })
        },
        created(){
            if (this.cart.length < 1) {
                this.$router.push('/cart')
            }
        },
        methods:{
            placeOrder (){
                let data = {}
                data = this.recipient
                data.orders = this.cart
                let loader = this.$loading.show()
                axios.post(process.env.VUE_APP_BASEURL+'/orders', data,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then((response) => {
                    console.log(response)
                    this.$store.commit("emptyCart")
                    this.$router.push('/orders')           
                })
                .catch((error) => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error. Try after some time!', {
                        timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            },
        }
    }
</script>
