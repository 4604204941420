<template>
<v-card>
    <v-card-text>
        <v-row>
            <v-col cols="12" class="#f5f5f5">
                <h3>Place Order</h3>
            </v-col>
            <v-col cols="12">
                <v-simple-table class="table mb-4">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th class="text-right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in cart" :key="item.product_id">
                            <td class="font-weight-bold text-left">{{item.name}}</td>
                            <td class="text-right">{{item.currency}}{{(item.discount_price * item.qty).toLocaleString()}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold text-left">Delivery Fee</td>
                            <td class="text-right">...</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold subtitle-1">Total:</td>
                            <td class="font-weight-bold subtitle-1 text-right">{{currency}}{{totalPrice.toLocaleString()}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>                
            </v-col>
        </v-row>
        <button-1 v-if="authToken" :text="'Pay / Place Order'" @clicked="placeOrder"/>
        <v-alert type="info" v-else>You need to Login / Register to Proceed</v-alert>
    </v-card-text>
</v-card>
</template>

<script>
import {mapState} from "vuex";
import Button1 from '../Buttons/Button1.vue';

export default {
  components: { Button1 },
    name: 'PreOrderPreviewCard',
    computed : {
        ...mapState({
            cart : state => state.cart,
            authToken : state => state.authToken,
        }),
        currency(){
            return this.cart.length > 0 ? this.cart[0].currency : null
        },
        totalPrice(){
            return this.cart.reduce((total, next) => {
                return total + (next.discount_price * next.qty)
            }, 0)
        }
    },
    methods:{
        placeOrder (){
            this.$emit('clicked', true)
        },
    }
}
</script>

<style scoped>
</style>