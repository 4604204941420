<template>
<v-card>
    <v-card-text>
        <v-row>
            <v-col cols="12" v-if="authToken">
                <h3 class="mb-3">Recipient Info</h3>
                <v-text-field
                    label="Recipient Name"
                    v-model="recipient.recipient_name"
                    @change="saveRecipientToVuex"
                    outlined
                ></v-text-field>
                <v-text-field
                    label="Phone Number"
                    v-model="recipient.recipient_phone"
                    hint="Ensure this number is correct"
                    @change="saveRecipientToVuex"
                    outlined
                ></v-text-field>
                <v-select
                    :items="nigerianStates"
                    label="Select State"
                    v-model="recipient.recipient_state_residence"
                    @change="saveRecipientToVuex"
                    outlined
                ></v-select>
                <v-textarea
                    label="Full Address"
                    v-model="recipient.recipient_address"
                    hint="Describe the address as thoroughly as possible"
                    @change="saveRecipientToVuex"
                    outlined
                ></v-textarea>           
            </v-col>
             <v-col cols="12" v-else>
                <sign-in-card v-if="loginForm"/>    
                <sign-up-card v-else />   
                  
                <a v-if="loginForm" @click="loginForm = false">Create Account Instead?</a>   
                <a v-else @click="loginForm = true">Login Instead</a>               
            </v-col>
        </v-row>
    </v-card-text>
</v-card>
</template>

<script>
import {mapState} from "vuex";
import SignInCard from '../Sections/SignInCard.vue';
import SignUpCard from '../Sections/SignUpCard.vue';

export default {
  components: { SignInCard, SignUpCard },
    name: 'ReceiverInfoCard',
    data: () => ({
        nigerianStates:[
            "Abia",
            "Adamawa",
            "Akwa Ibom",
            "Anambra",
            "Bauchi",
            "Bayelsa",
            "Benue",
            "Borno",
            "Cross River",
            "Delta",
            "Ebonyi",
            "Edo",
            "Ekiti",
            "Enugu",
            "FCT - Abuja",
            "Gombe",
            "Imo",
            "Jigawa",
            "Kaduna",
            "Kano",
            "Katsina",
            "Kebbi",
            "Kogi",
            "Kwara",
            "Lagos",
            "Nasarawa",
            "Niger",
            "Ogun",
            "Ondo",
            "Osun",
            "Oyo",
            "Plateau",
            "Rivers",
            "Sokoto",
            "Taraba",
            "Yobe",
            "Zamfara"
        ],
        loginForm:true,
    }),
    computed : {
        ...mapState({
            authToken : state => state.authToken,
            recipient : state => state.recipient,
        })
    },
    methods:{
        saveRecipientToVuex (){
            this.$store.commit("recipient", this.recipient)
        },
    }
}
</script>

<style scoped>
</style>